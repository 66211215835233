import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useCheckoutData, getDisplayedDate } from "../actions/Checkout"; 
import { usePaymentLogic  } from '../actions/Payments';        // Import the navigation logic
import { navigateTo  } from '../actions/Forminputvalidation'; 
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import styles for react-toastify
import "../tabsactive.css"; 
import Payments2 from   '../components/Stripetest2'; 
import EmailTemplateShippingBooked from "../actions/EmailTemplateShippingBooked";

// import {Stripetest,StripetestForm   } from '../actions/Stripetest'
// import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js"; 
// import { loadStripe } from "@stripe/stripe-js";
// // Load Stripe with your publishable key
// const stripePromise = loadStripe("pk_test_51JHcjbB5YXKsaT5SvfVQpmajx8ewVrKn50wWubaB2uuclQHG9aSLo0RMVeJ2FCZZ3w8AAfHFUxGVAlnZzCapBDjc00u8ToTaza");
 

const Payment = () => {
    const { uniqueId } = useParams(); 
    const { shippingInfo, distance,  loading, error } = useCheckoutData(uniqueId);     
    const { shippingData, handleInputChange, handleRadioChangeYouAre, setContactStates, contactStates, handleSubmit, activeSection,setActiveSection  } = usePaymentLogic();   
    const location = useLocation();  
    const { selectedPrice, vehicleCondition, transportType, transportFrom, transportTo, dismilevalue, vehicle, firstAvailDate } = location.state || {}; // Retrieve state from navigation
   // const [userType, setUserType] = useState("Individual");
   // const [pickupType, setPickupType] = useState("Residential");
    //  const [contactMethod, setContactMethod] = useState("Me");
    //  const [pickupContactName, setPickupContactName] = useState("");
    //  const [pickupContactPhone, setPickupContactPhone] = useState("");

    // State for Delivery Details
   // const [deliveryType, setDeliveryType] = useState("Residential");
    // const [contactMethodDelivery, setContactMethodDelivery] = useState("Me");
    // const [deliveryContactName, setDeliveryContactName] = useState("");
    // const [deliveryContactPhone, setDeliveryContactPhone] = useState("");
         

    const handleNavigate = (section) => {
      setActiveSection(section); // Update active section
      navigateTo(section); // Call navigation logic from the JS file
    };
    
        const amount = ((selectedPrice.price)*100).toFixed(0); // Amount in the smallest currency unit (e.g., cents)
        const currency = "cad"; // Currency code
    
        const shippingDetails = {
            distance: distance || 'Calculating...',
            firstAvailableDate: firstAvailDate ,
            vehicle: vehicle  || 'N/A',
            shipFrom: transportFrom || 'N/A',
            shipTo:  transportTo || 'N/A',
            vehicleCondition: vehicleCondition || 'N/A',
            transportType: transportType || 'N/A',
            insurance: "Included",
            transitTime:   "7-9 days",
            price:  <span> <strong>${selectedPrice ? selectedPrice.price : "0"}</strong> {selectedPrice ? selectedPrice.name : ""} </span>,
        };

    if (loading) return <div>Loading...</div>;
    if (error) return <div style={{ color: "red" }}>{error}</div>;
    if (!shippingInfo) return <div>No shipping information available.</div>;

    return (
        <Container className="mt-8">   
           <ToastContainer  position="top-center" />
           <div className="details-row">   </div>          
    <div className="col-md-8"> 
                    <div className="header">
                            <div
                            id="transport-header"
                            className={activeSection === 'transport' ? 'active' : 'disabled'}
                            onClick={() => handleNavigate('transport')}
                            >
                            Transport
                            </div>
                            <div
                            id="pickup-header"
                            className={activeSection === 'pickup' ? 'active' : 'disabled'} 
                            onClick={() => activeSection === 'transport' && handleNavigate('pickup')}
                            >
                            Pickup
                            </div>
                    
                            <div
                            id="delivery-header"
                            className={activeSection === 'delivery' ? 'active' : 'disabled'}
                            onClick={() => activeSection === 'pickup' && handleNavigate('delivery')}
                            >
                            Delivery
                            </div>
                            <div
                            id="book-shipment-header"
                            className={activeSection === 'book-Payment' ? 'active' : 'disabled'}
                            onClick={() => activeSection === 'delivery' && handleNavigate('book-Payment')} 
                            >
                            Payment
                            </div>
                         
                        </div>
  <form onSubmit={handleSubmit}>    
     <div className={`section ${activeSection === 'transport' ? 'active' : ''}`} id="transport-section"> <br/>
    
          {/* Right Section - Transport Details Form */}            
          <h2>Transport </h2>
                        <div className="form-group">
                             You are   * <br/>
                                                <input type="radio" name="youAre" value="Individual" 
                                                 checked={shippingData.youAre === "Individual"}
                                                 onChange={() => handleRadioChangeYouAre("youAre", "Individual")}
                                                 /> 
                                                 Individual                                
                            <br/>
                                                <input type="radio" name="youAre" value="Business"                    
                                                checked={shippingData.youAre === "Business"}
                                                onChange={() => handleRadioChangeYouAre("youAre", "Business")}
                                                />
                                                Business
                            <br/>
                             
                        </div>
                        <div className="form-group"> <br/>
                            <label>Full Name *</label>
                            <input type="text" name="fullName" className="form-control" placeholder="Enter your full name"   value={shippingData.fullName}  onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label>Email *</label>
                            <input type="email"  name="custEmail"  className="form-control" placeholder="Enter your email"  value={shippingData.custEmail}   onChange={handleInputChange}/> 
                        </div>
                        <div className="form-group">
                            <label>Phone Number *</label>
                            <input type="tel" name="custPhone" className="form-control" placeholder="Enter your phone number"  value={shippingData.custPhone}   onChange={handleInputChange}/> 
                        </div>  

            
        <button className="button"  type="button"  onClick={() => handleNavigate('pickup')}>Continue</button>
      </div>

      <div className={`section ${activeSection === 'pickup' ? 'active' : ''}`} id="pickup-section"> <br/>         
        {/* Vehicle Pickup Details */}
        <h2>Vehicle Pickup Details</h2>
                        <div className="form-group">
                            <label>Pickup Address *</label>
                            <input
                                type="text" 
                                name="pickupAddress" 
                                className="form-control"
                                placeholder="Enter pickup address"  value={shippingData.pickupAddress}   onChange={handleInputChange} />                      
                        </div>

                        <div className="form-group">
                             This is a *<br />                              
                                    <input
                                        type="radio"
                                        name="thisIsA" 
                                        value="Residential"
                                        checked={shippingData.thisIsA === "Residential"}
                                        onChange={() => handleRadioChangeYouAre("thisIsA", "Residential")}
                                    />
                                     Residential address
                                    <br />    
                                    <input
                                        type="radio"
                                        name="thisIsA"
                                        value="Business" 
                                        checked={shippingData.thisIsA === "Business"}
                                        onChange={() => handleRadioChangeYouAre("thisIsA", "Business")}
                                    />
                                     Business address                                 
                        </div>

                        <div className="form-group">  
                            Who do we contact about pickup? *<br />                                
                                    <input
                                        type="radio"
                                        name="pickupContact"
                                        value="Me"
                                        checked={shippingData.pickupContact === "Me"}
                                        onChange={() => {
                                            handleRadioChangeYouAre("pickupContact", "Me");
                                            setContactStates((prev) => ({
                                                ...prev,
                                                pickup: false, // Hide the contact fields when "Contact me" is selected
                                            }));
                                        }}
                                    />
                                     Contact me
                                    <br />                                 
                                    <input
                                        type="radio"
                                        name="pickupContact"
                                        value="SomeoneElse"
                                        checked={shippingData.pickupContact === "SomeoneElse"}
                                        onChange={() => {
                                            handleRadioChangeYouAre("pickupContact", "SomeoneElse");
                                            setContactStates((prev) => ({
                                                ...prev,
                                                pickup: true, // Show the contact fields when "Contact someone else" is selected
                                            }));
                                        }}
                                    />
                                    Contact someone else                               
                        </div>
                        {/* Conditional Pickup Contact */}
                        {contactStates.pickup && (
                            <>
                                <div className="form-group">
                                    <label>Pickup Contact's Full Name</label>
                                    <input
                                        type="text"
                                        name="contactFullName"
                                        className="form-control"
                                        placeholder="Enter contact's full name"
                                        value={shippingData.contactFullName}
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input
                                        type="tel"
                                        name="pickupPhone"
                                        className="form-control"
                                        placeholder="Enter phone number"
                                        value={shippingData.pickupPhone}
                                        onChange={handleInputChange}
                                    /> 
                                </div>
                            </>
                        )}
         <button className="button"  type="button" onClick={() => handleNavigate('transport')}>Back</button> | 
        <button className="button"  type="button"  onClick={() => handleNavigate('delivery')}>Continue</button>
      </div>
    
      <div className={`section ${activeSection === 'delivery' ? 'active' : ''}`} id="delivery-section"> <br/> 
           
            {/* Vehicle Delivery Details */}
            <h2>Vehicle Delivery Details</h2>
                        <div className="form-group">
                            <label>Delivery Address</label>
                            <input
                                type="text" 
                                name="deliveryAddress"
                                className="form-control"
                                placeholder="Enter delivery address"  value={shippingData.deliveryAddress}   onChange={handleInputChange} />   
                             
                        </div>
                        <div className="form-group">
                             This is a <br/>
                                
                                    <input
                                        type="radio"
                                        name="thisIsADelivery"
                                        value="Residential"
                                        checked={shippingData.thisIsADelivery === "Residential"} 
                                        onChange={() => handleRadioChangeYouAre("thisIsADelivery", "Residential")}
                                    /> 
                                    Residential address
                                    <br/>  
                                    <input
                                        type="radio"
                                        name="thisIsADelivery"
                                        value="Business"
                                        checked={shippingData.thisIsADelivery === "Business"} 
                                        onChange={() => handleRadioChangeYouAre("thisIsADelivery", "Business")} 
                                    />
                                    Business address                                                            
                        </div>
                        <div className="form-group">
                            Who do we contact about delivery?  <br/>                            
                                    <input
                                        type="radio"
                                        name="deliveryContact"
                                        value="Me"
                                        checked={shippingData.deliveryContact === "Me"}
                                        onChange={() => {
                                            handleRadioChangeYouAre("deliveryContact", "Me");
                                            setContactStates((prev) => ({
                                                ...prev,
                                                delivery: false, // Hide the contact fields when "Contact me" is selected
                                            }));
                                        }}
                                    />

                                    Contact me
                                    <br/>
                                    <input
                                        type="radio"
                                        name="deliveryContact"
                                        value="SomeoneElse"
                                        checked={shippingData.deliveryContact === "SomeoneElse"}
                                        onChange={() => {
                                            handleRadioChangeYouAre("deliveryContact", "SomeoneElse");
                                            setContactStates((prev) => ({
                                                ...prev,
                                                delivery: true,
                                            }));
                                        }}
                                    />
                                    Contact someone else
                               
                        </div>
                        {contactStates.delivery  && (
                            <>
                                <div className="form-group">
                                    <label>Delivery Contact's Full Name</label>
                                    <input
                                        type="text"
					                    name="deliveryFullName"
                                        className="form-control"
                                        placeholder="Enter contact's full name"
                                        value={shippingData.deliveryFullName}
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input
                                        type="tel"                                        
					                    name="deliveryPhone"
                                        className="form-control"
                                        placeholder="Enter phone number"
                                        value={shippingData.deliveryPhone}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </>
                        )} 

                            {/* --------------- */}
    

                            {/* ------------------ */}


        <button className="button"  type="button"  onClick={() => handleNavigate('pickup')}>Back</button> |
          {/* <button className="button"  type="button"  onClick={() => handleNavigate('book-Payment')}>Continue</button>   */}
        {/* <button  type="submit" className="button"   >Submit</button>   */}

        <button type="submit" className="button" disabled={loading} style={{ marginTop: "20px" }}>
                {loading ? "Processing..." : "Submit"}
            </button>
      </div>
 </form>
      <div className={`section ${activeSection === 'book-Payment' ? 'active' : ''}`} id="book-shipment-section"> <br/>
                    <Payments2 amount={amount} currency={currency} />  

                <button className="button"  type="button"   onClick={() => handleNavigate('delivery')} >Back</button> 
      </div>
     
    
      {/* <EmailTemplateShippingBooked {...shippingDetails} /> */}
</div>                    
            {/* Left Section - Details */}
                <div className="col-md-4">
                    <h4 className="details-title">Details</h4>
                    <div className="details-box">
                        <div className="details-row">
                            <strong>Distance: </strong> {distance || 'Calculating...'}
                        </div>
                        <div className="details-row">
                            <strong>First avail. date:</strong>{" "}
                            <span className="highlight-text">{shippingInfo.firstavaildate}</span> (
                            {getDisplayedDate(shippingInfo.firstavaildate)})
                        </div>
                        <div className="details-row">
                            <strong>Vehicle:</strong> {shippingInfo.vehicleyear} {shippingInfo.vehiclemake}{" "}
                            {shippingInfo.vehiclemodel}
                        </div>
                        <div className="details-row">
                            <strong>Ship from:</strong> {shippingInfo.transportfrom}
                        </div>
                        <div className="details-row">
                            <strong>Ship to:</strong> {shippingInfo.transportto}
                        </div>
  
                        <div className="details-row">
                            <strong>Vehicle Condition:</strong> <span>{vehicleCondition || 'N/A'}</span>
                        </div>
                        <div className="details-row">
                            <strong>Transport Type:</strong> <span>{transportType || 'N/A'}</span>
                        </div>
                  
                         
                        <div className="details-row"><strong>Insurance:</strong> Included</div>
                        <div className="details-row"><strong>Transit time:</strong> 7-9 days</div>     

                        <div className="details-row"><strong>Price option:</strong> 
                                <span> <strong>${selectedPrice ? selectedPrice.price : "0"}</strong> {selectedPrice ? selectedPrice.name : ""} </span>
                        </div> 
                        {uniqueId}
                    </div>  
                </div>
                <div className="col-md-4">  <hr/> <br/><br/> </div>           
        </Container>
    );
};

export default Payment;
