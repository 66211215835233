import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify'; // Import toast for notifications
import { validateShippingInfo  } from '../actions/Forminputvalidation'; 
import EmailTemplateShippingBooked from "../actions/EmailTemplateShippingBooked";
import emailjs from "emailjs-com";
import ReactDOMServer from "react-dom/server";

  
  export const usePaymentLogic = () => {
    const location = useLocation();  
    const { selectedPrice, vehicleCondition, transportType, transportFrom, transportTo, dismilevalue, vehicle, firstAvailDate } = location.state || {}; // Retrieve state from navigation
    const { uniqueId } = useParams(); // Extract uniqueId from URL parameters  
    const [shippingData, setShippingData] = useState({
      quoteId: uniqueId, // Set quoteId to the uniqueId from URL
      transportFrom: transportFrom || "-",
      transportTo: transportTo || "-",
      distance: dismilevalue || "0",
      firstAvailDate: firstAvailDate || "",
      transportType: transportType,
      insuranceType: "Included",
      vehicle: vehicle || "",
      vehicleCategory: "",
      vehicleCondition: vehicleCondition,
      priceOption: selectedPrice.name,
      billAmount: selectedPrice.price,
      taxes: "",
      finalPrice: "",
      youAre: "",
      fullName: "",
      custEmail: "",
      custPhone: "",
      pickupAddress: "",
      thisIsA: "",
      pickupContact: "",
      contactFullName: "",
      pickupPhone: "",
      deliveryAddress: "",
      thisIsADelivery: "",
      deliveryContact: "",
      deliveryFullName: "",
      deliveryPhone: "",
      status:"1",
    });
   
    
    // State for billing address selection     
    const [activeSection, setActiveSection] = useState("transport"); // Track active section
    const [contactStates, setContactStates] = useState({
      pickup: false,
      delivery: false,
  });

     
  const [shippingDetails, setShippingDetails] = useState({
          distance: dismilevalue || 'Calculating...',
          firstAvailableDate: firstAvailDate ,
          vehicle: vehicle  || 'N/A',
          shipFrom: transportFrom || 'N/A',
          shipTo:  transportTo || 'N/A',
          vehicleCondition: vehicleCondition || 'N/A',
          transportType: transportType || 'N/A',
          insurance: "Included",
          transitTime:   "7-9 days",
          price:  <span> <strong>${selectedPrice ? selectedPrice.price : "0"}</strong> {selectedPrice ? selectedPrice.name : ""} </span>,
  });
  
    const handleShippingChange = (e) => {
      const { name, value } = e.target;
      setShippingData({ ...shippingData, [name]: value });
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setShippingData((prev) => ({
          ...prev, [name]: value,   }));          
  };
 
    const handleRadioChangeYouAre = (name, value) => {
      setShippingData((prev) => ({
          ...prev,
          [name]: value,
      }));
  };

    
    const handleSubmit = async (e) => {
      e.preventDefault(); 

      if (!validateShippingInfo(shippingData, setActiveSection)) {
          return; // Stop submission if validation fails
      } 
   
              // // Convert React component to string using EmailJS template
              // const emailHTML = `<div>
              //   <h2>Shipping Details</h2>
              //   ${EmailTemplateShippingBooked(shippingDetails)}
              // </div>`;

            // // Render the React component into a string
            // const emailHTML = ReactDOMServer.renderToStaticMarkup(
            //     <EmailTemplateShippingBooked {...shippingDetails} />
            // );

      try {
        // // // Post Shipping Data
        const shippingResponse = await axios.post(
          "https://api.shoppervista.com/api/Shippingbooked",
          shippingData
        );
        console.log("Shipping Data Response:", shippingResponse.data);
                                    
          //   const response = await emailjs.send(
          //     "service_aykyihs", // Replace with your EmailJS Service ID
          //     "template_wnc02r2", // Replace with your EmailJS Template ID
          //     {
          //         to_name: shippingData.contactFullName, // Replace with actual name
          //         to_email: "tuahaca@gmail.com", // Replace with recipient email
          //         message: emailHTML, // Use the rendered HTML string here
          //     },
          //     "izu-tt6TrcFqMyekk" // Replace with your EmailJS Public Key
          // );
          // console.log("Email sent successfully:", response.status, response.text);

       // toast.success("Shipping details submitted successfully!");
       // console.log("Card Info Response:", cardInfoResponse.data); 
        setActiveSection("book-Payment");
          
      } catch (error) {
        console.error("Error submitting payment details:", error);
        toast.error("An error occurred. Please try again.");
      }
    };
  
    return {
      shippingData,
      handleShippingChange, handleRadioChangeYouAre, handleInputChange,      
      handleSubmit, activeSection,   setActiveSection, setContactStates, contactStates,
    };
  };