import React from "react";
import { useSearchParams } from "react-router-dom";

const PaymentSuccess = () => {
    const [searchParams] = useSearchParams();
    const amount = searchParams.get("amount");

    // Convert the amount from cents to dollars (if using USD)
    const formattedAmount = (amount / 100).toFixed(2);

    return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>  
            <h1>Thank You for Your Payment!</h1>
            <p>You paid ${formattedAmount}.</p>
            <p>Your shipment has been successfully booked, and our representative will reach out to you shortly with the details</p>
        </div>
    );
};

export default PaymentSuccess;
