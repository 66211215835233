import axios from 'axios';
import { useState, useEffect } from 'react';


// Helper function to calculate the future date
export const calculateFutureDate = (baseDate, daysToAdd) => {
    const date = new Date(baseDate);
    date.setDate(date.getDate() + daysToAdd);
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
  };
  
  // Function to determine the displayed date
  export const getDisplayedDate = (firstAvailDate) => {
    const currentDate = new Date();
  
    switch (firstAvailDate) {
      case "As soon as possible":
        return calculateFutureDate(currentDate, 2); // 2 days ahead
      case "Within 2 weeks":
        return calculateFutureDate(currentDate, 14); // 2 weeks ahead
      case "Within 30 days":
        return calculateFutureDate(currentDate, 30); // 30 days ahead
      case "More than 30 days":
        return calculateFutureDate(currentDate, 35); // 35 days ahead
      default:
        return "Invalid date range";
    }
  };
  
     // Google API Key
const googleApiKey = 'AIzaSyBSQ2eHtJbnjd_PdMEPbxbm5Nl_3P2-9hw'; // Replace with your API key 

export const useCheckoutData = (uniqueId) => {
  const [shippingInfo, setShippingInfo] = useState(null);
  const [distance, setDistance] = useState('');
  const [dismile, setdismile] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ferryCharge, setFerryCharge] = useState(0); // Tracks ferry surcharge

  useEffect(() => {
    const fetchShippingInfo = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch shipping info from your backend API
        const response = await axios.get(
          `https://api.shoppervista.com/api/Shippinginfo/search/tkid?Id=${uniqueId}`
        );

        if (response.data) {
          setShippingInfo(response.data);

          const { transportfrom, transportto } = response.data;

          // Fetch distance using Google Distance Matrix API
          const distanceResponse = await axios.get(
            `https://www.shoppervista.com:5000/api/distance`,
            {
              params: {
                origins: transportfrom,
                destinations: transportto,
                key: googleApiKey,
                alternatives: false,
              },
            }
          );

          const route = distanceResponse.data?.routes[0];
          if (route) {
              const distanceInKm = route.legs[0].distance.value / 1000; // Distance in kilometers
              const distanceInMiles = (distanceInKm / 1.609).toFixed(2); // Convert to miles
              const usesFerry = route.legs[0].steps.some(step => step.travel_mode === 'FERRY');

              setDistance(`${distanceInMiles} miles (${distanceInKm.toFixed(2)} km)`);
              setdismile(distanceInMiles);

                    // Check for ferry crossing and apply surcharge if needed
                    setFerryCharge(usesFerry ? 1500 : 0);
                } else {
                    setError('No routes found. Please check the transport details.');
                }
            } else {
                setError('No data found for the given ID.');
            }
        } catch (err) {
      console.error('Error fetching data:', err.response?.data || err.message);
      setError('Failed to fetch data. Please try again later.');
  } finally {
      setLoading(false);
  }
};

fetchShippingInfo();
}, [uniqueId]);

return { shippingInfo, distance, dismile, ferryCharge, loading, error };
};

 
export const calculatePriceOptionsWithCondition = (dismile, isNonRunning, transportType, ferryCharge) => { 
  const distanceInMiles = parseFloat(dismile) || 0;
  let basePrice = distanceInMiles;
  let multiplier = 1;

  // Determine multiplier based on distance range and transport type
  if (distanceInMiles <= 500) {
    multiplier = transportType === "Open" ? 3.5 : 5.5;
  } else if (distanceInMiles <= 1000) {
    multiplier = transportType === "Open" ? 2 : 3.5;
  } else if (distanceInMiles <= 2500) {
    multiplier = transportType === "Open" ? 1.2 : 2.5;
  } else {
    multiplier = transportType === "Open" ? 1.1 : 2.2;
  }

  // Adjust base price
  basePrice *= multiplier;

 // Add extra cost for Non-Running condition and ferry surcharge
 const extraCost = (isNonRunning ? 800 : 0) + ferryCharge;

  return [
    { name: 'Regular price', price: (basePrice + extraCost).toFixed(0) },
    { name: 'Discounted cash price', price: (basePrice + extraCost - 50).toFixed(0) },
    { name: 'Door to Door Service', price: (basePrice + extraCost + 400 ).toFixed(0) },
    { name: 'Terminal to Terminal Service', price: (basePrice + extraCost + 200 ).toFixed(0) },
   // { name: 'Railway Service', price: (basePrice + extraCost + 100 ).toFixed(0) },
  ];
};

  