import React, { useState, useEffect } from "react";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import { Container } from 'react-bootstrap';

// Load Stripe with your public key
const stripePromise = loadStripe("pk_test_51JHcjbB5YXKsaT5SvfVQpmajx8ewVrKn50wWubaB2uuclQHG9aSLo0RMVeJ2FCZZ3w8AAfHFUxGVAlnZzCapBDjc00u8ToTaza");

const appearance = {
    theme: 'flat',
    variables: {
        fontFamily: 'Arial, sans-serif',
        colorPrimary: '#6c63ff',
        colorText: '#4b4b4b',
        spacingUnit: '4px',
        borderRadius: '6px',
    },
    rules: {
        '.Input': {
            borderColor: '#dcdcdc',
        },
        '.Label': {
            color: '#6c63ff',
            fontSize: '14px',
        },
    },
};

const PaymentForm2 = ({ clientSecret, amount }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            alert("Stripe is not loaded yet.");
            return;
        }

        setLoading(true);

        try {
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    // Append the amount to the return_url
               // return_url: "", //`http://localhost:3000/payment-success?amount=${amount}`,
                return_url: `https://shoppervista.com/payment-success?amount=${amount}`,
                },
            });

            if (error) {
                toast.error(`Payment failed: ${error.message}`);
            } else {
                toast.error("Payment successful!");
            }
        } catch (error) {
            console.error("Error during payment:", error);
            toast.error("An error occurred during the payment process.");              
        }

        setLoading(false);
    };

    return (        
        // style={{ maxWidth: "600px", marginRight: "auto", marginLeft: "0" }}>
        <Container className="mt-8">   
           <ToastContainer />
        <form onSubmit={handleSubmit}  style={{ maxWidth: "600px", marginRight: "auto", marginLeft: "0" }}>
           <h3>Enter Payment Details </h3>
            <PaymentElement />
            
            <button type="submit" className="btn btn-success" disabled={!stripe || loading} style={{ marginTop: "20px" }}>
                {loading ? "Processing..." : "Pay Now"}
            </button>
            <br/><br/><br/>
        </form>
        </Container>
    );
};



const Payments2 = ({ amount, currency }) => {
    const [clientSecret, setClientSecret] = useState("");

   

    // Fetch the client secret from the backend
    const fetchClientSecret = async () => {
        try {
            const response = await fetch("https://shoppervista.com:4010/api/create-payment-intent", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
               // body: JSON.stringify({ amount: 1110, currency: "cad" }), // $10.00
                body: JSON.stringify({ amount, currency }), // Use the passed amount and currency https://www.shoppervista.com:4010/api/create-payment-intent
            });

            const data = await response.json();
            setClientSecret(data.clientSecret);
        } catch (error) {
            console.error("Error fetching client secret:", error.message);
        }
    };

    useEffect(() => {
        fetchClientSecret();
    },[amount, currency]); // Add dependencies to re-fetch if amount or currency changes

    if (!clientSecret) {
        return <div>Loading payment information...</div>;
    }

    return (
        <Elements stripe={stripePromise} options={{ appearance, clientSecret }}>
            <PaymentForm2 clientSecret={clientSecret} amount={amount} />
        </Elements>        
    );
};

export default Payments2;
