import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import VehicleInfoForm from "./components/VehicleInfoForm";
import { Footer } from "./components/footer";
import { Quote } from "./components/quote";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Checkout from './components/Checkout';
import Payment from './components/Payment';
import StripetestForm from './components/Stripetest'; 
import PaymentForm2 from './components/Stripetest2'; 
import PaymentSuccess from "./components/PaymentSuccess";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <MainContent landingPageData={landingPageData} />
    </Router>
  );
};

const MainContent = ({ landingPageData }) => {
  const location = useLocation();

  return (
    <div>
      <Navigation /> <br/><br/><br/><br/> 
      

      {/* Conditionally render components based on the current path */}
      {location.pathname === '/' && (
        <>
          <Header data={landingPageData.Header} />
          <Features data={landingPageData.Features} />
          <About data={landingPageData.About} />
          <Services data={landingPageData.Services} /> 
          {/* <Gallery data={landingPageData.Gallery} /> */}
          <Testimonials data={landingPageData.Testimonials} />
          {/* <Team data={landingPageData.Team} /> */}
          <Contact data={landingPageData.Contact} />         
        </>
      )}

      <main>
        <Routes>          
          <Route path="/Quote" element={<Quote />} />
          <Route path="/VehicleInfoForm" element={<VehicleInfoForm />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Checkout/:uniqueId"  element={<Checkout />} /> 
          <Route path="/Checkout/:uniqueId/payment" element={<Payment />} />
          <Route path="/StripetestForm" element={<StripetestForm />} /> 
          <Route path="/PaymentForm2" element={<PaymentForm2 />} /> 
          <Route path="/payment-success" element={<PaymentSuccess />} />

          {/* Add other routes here */}
        </Routes>
      </main>

      <Footer />
    </div>
  );
};


export default App;
