import React, {  useState,useEffect } from 'react'; 
import { useParams, useNavigate } from 'react-router-dom'; // Added useNavigate
//import axios from 'axios';
import { Container} from 'react-bootstrap';
import { getDisplayedDate, useCheckoutData, calculatePriceOptionsWithCondition  } from "../actions/Checkout";
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS


const Checkout = () => {  

  const { uniqueId } = useParams();
  const navigate = useNavigate(); // For navigation
  const { shippingInfo, distance, dismile, ferryCharge, loading, error } = useCheckoutData(uniqueId);
  
  const [isNonRunning, setIsNonRunning] = useState(false); // Tracks vehicle condition
  const [transportType, setTransportType] = useState("Open"); // Tracks transport type
  const [selectedPrice, setSelectedPrice] = useState(null); // Tracks selected price
  const [priceOptions, setPriceOptions] = useState([]); // Tracks price options
 

  const handleConditionChange = (event) => {
    setIsNonRunning(event.target.value === "Non-running");
  };

  const handleTransportTypeChange = (event) => {
    setTransportType(event.target.value);
  };

  const handlePriceChange = (option) => {
    //console.log("Option Selected:", option); // Debugging log
    setSelectedPrice(option);
  };

  
const handleContinueToBooking = () => {
  // Check if the selected price is active
  const isActivePrice = priceOptions.some(
      (option) => 
          selectedPrice &&
          option.name === selectedPrice.name &&
          option.price === selectedPrice.price
  );

  // Validation: Ensure an active price is selected and its value is greater than 0
  if (!isActivePrice || parseFloat(selectedPrice.price) <= 0) {
      toast.error("Please select a price option"); // Show error if validation fails
      return; // Prevent navigation
  }

  // Navigate to the Payment page with the selected price
  navigate(`/Checkout/${uniqueId}/payment`, { state: { selectedPrice, 
    vehicleCondition: isNonRunning ? "Non-running" : "Running", transportType,
     transportFrom: shippingInfo.transportfrom, transportTo: shippingInfo.transportto, dismilevalue: dismile,
     vehicle: shippingInfo.vehicleyear + " " + shippingInfo.vehiclemake + " " + shippingInfo.vehiclemodel,
     firstAvailDate: shippingInfo.firstavaildate + " " + getDisplayedDate(shippingInfo.firstavaildate)  } });
};


  useEffect(() => {
    if (shippingInfo) {
      setIsNonRunning(shippingInfo.vehiclecondition === "Non-running");
      setTransportType(shippingInfo.transporttype || "Open");
    }
  }, [shippingInfo]);

  useEffect(() => {
    const options = calculatePriceOptionsWithCondition(dismile, isNonRunning, transportType, ferryCharge);
    setPriceOptions(options);

    if (!selectedPrice && options.length > 0) {
      setSelectedPrice(options[0]);
    }
  }, [dismile, isNonRunning, transportType, ferryCharge, selectedPrice]);

 
  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ color: "red" }}>{error}</div>;
  if (!shippingInfo) return <div>No shipping information available.</div>;


  return (      
      <Container className="mt-8">   
        <ToastContainer />
            <div className="details-row">  </div>
                    
          {/* New col-8 section */}
        <div className="col-md-8">
              <h3>Ta-da!</h3>
              <div className="alert alert-info text-left"> 
                   {/* Your quote has been e-mailed to you.<br /> */}
                 Ready to book? Hooray! {/*  <strong><span className="text-dark">NO PAYMENT REQUIRED</span></strong> to book your shipment. */}
              </div>

             
        <div className="row">            
            <div className="col-lg-12">                
                <div className="row text-left mb-4">
                    <div className="col-md-6">
                        <strong>Transit time:</strong> <span> 7-9 days</span>
                    </div>
                    <div className="col-md-6">
                        <strong>Insurance:</strong> <span className="fw-bold">Included</span>
                    </div>
                    <div className="details-row"><br />  </div> <br />
               </div>

               
     {/* Price Options */}
                <div className="row">
                  {priceOptions.map((option, index) => (
                    <div className="col-md-6 mb-4" key={index}>
                      <div
                        className={`price-card ${
                          selectedPrice && selectedPrice.price === option.price && selectedPrice.name === option.name
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handlePriceChange(option)}
                      >
                        <h3>{option.name}</h3>
                        <div className="price-value">${option.price}</div>
                        <p>
                          {option.name === "Regular price"
                            ? "Once the order is assigned to a carrier, one-time payment in full by using your credit card or debit card will be charged."
                            : option.name === "Discounted cash price"
                            ? "Once the order is assigned to a carrier, a partial payment will be charged, the balance will be due in cash on delivery."
                            : option.name === "Door to Door Service"
                            ? "Vehicle is picked up and dropped off to your specified location. Enjoy the ultimate convenience pickup and delivery right at your doorstep."
                            : option.name === "Terminal to Terminal Service"
                            ? "Save money by dropping off the vehicle at one of our car terminals near you. We will locate a partnered terminal near you."
                            : "Choose our most cost-efficient option and transport your vehicle between our car terminals. Opt for our Railway service for a dependable and cost-effective transport solution with the reliability of rail"}
                        </p>
                      </div><br/>
                    </div>
                  ))}
                </div> 
                

                    {/* Continue to booking details button */}
                    <div className="text-left mt-4"> <br/>  
                        <button
                            className="btn btn-booking px-8 py-2 rounded"
                            onClick={handleContinueToBooking} // Add onClick handler
                        >
                            Continue to booking details
                        </button> <br/> <br/> <br/>
                    </div>

               
                <div className="text-left mt-4">
                    <p>
                        Don't know the exact day? That's ok! You can change at any time. <br />
                        You will still be able to review your order.
                    </p>
                    <p>- OR -</p>
                    <p>Book with one of our friendly Customer Service Agents!<br />
                        <a href="tel:+18554343477" className="text-primary fw-bold">+1 (855) 434-3477 </a>
                    </p>
                    <p><div className="details-row"> <p>Checkout ID: {uniqueId} Ferry Surcharge:${ferryCharge}</p> </div></p>
                </div>
            </div> 
         </div>
       </div>   

          {/* Checkout Container on the left side with col-4 */}
          <div className="col-md-4  ">
          <br /> 
           
                <h4 className="details-title">Details</h4>
                <div className="details-box">
             
                    <div className="details-row"><strong>Distance: </strong> {distance || 'Calculating...'}  </div>
                    <div className="details-row"><strong>First avail. date:</strong> <span class="highlight-text">{shippingInfo.firstavaildate}</span> ( {getDisplayedDate(shippingInfo.firstavaildate)}) </div>   
                    <div className="details-row"><strong>Vehicle:</strong> {shippingInfo.vehicleyear} {shippingInfo.vehiclemake} {shippingInfo.vehiclemodel} </div>
                    <div className="details-row"><strong>Ship from:</strong> {shippingInfo.transportfrom}</div>
                    <div className="details-row"><strong>Ship to:</strong> {shippingInfo.transportto}  </div>

                    <div className="details-row"><strong>Vehicle condition:</strong> <br/>
                
                      <label>                      
                          <input
                            type="radio"  
                            name="vehicleCondition"
                            value="Running"
                            checked={!isNonRunning}
                            onChange={handleConditionChange}
                          /> 
                          Running 
                      </label> 

                      <label className="ml-6">
                        <input
                          type="radio"
                          name="vehicleCondition"
                          value="Non-running"
                          checked={isNonRunning}
                          onChange={handleConditionChange}
                        />
                           Non-running
                      </label> <br/>
                    </div>

                    <div className="details-row"><strong>Transport type:</strong>   <br/> 
                          <label>
                            <input
                              type="radio"
                              name="transportType"
                              value="Open"
                              checked={transportType === "Open"}
                              onChange={handleTransportTypeChange}
                            />
                            Open
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="transportType"
                              value="Enclosed"
                              checked={transportType === "Enclosed"}
                              onChange={handleTransportTypeChange}
                            />
                            Enclosed
                          </label><br/>
                        </div>
                         
                        <div className="details-row"><strong>Insurance:</strong> Included</div>
                        <div className="details-row"><strong>Transit time:</strong> 7-9 days</div>                     
                        <div className="details-row"><strong>Price option:</strong> 
                        <span> <strong>${selectedPrice ? selectedPrice.price : "0"}</strong> {selectedPrice ? selectedPrice.name : ""} </span>
                        </div>               
                   
                </div>  
                <br/>
          </div>
    </Container>
  );
};

export default Checkout;
