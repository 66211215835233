// Shippinginfo.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const useShippingInfoForm = () => {
  const [formData, setFormData] = useState({
    id: '',
    transportfrom: '',
    transportto: '',
    transporttype: 'Open',
    vehicleyear: '2010',
    vehiclemake: '',
    vehiclemodel: '',
    operable: 'Yes',
    vehiclecondition: 'Running',
    custemail: '',
    firstavaildate: 'As soon as possible',
    custphone: '',
  });


const currentYear = new Date().getFullYear(); 
const yearOptions = [];

for (let year = 1899; year <= currentYear + 1; year++) {
  yearOptions.push(<option key={year} value={year}>{year}</option>);
}

  const dateOptions = [
    "As soon as possible",
    "Within 2 weeks",
    "Within 30 days",
    "More than 30 days"
  ];

  const [errors, setErrors] = useState({});
  const [vehicleData, setVehicleData] = useState([]);
  const [models, setModels] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchVehicleData();
  }, []);

  const fetchVehicleData = async () => {
    try {
      const response = await axios.get('https://api.shoppervista.com/api/Vehicleinfo');
      setVehicleData(response.data || []);
    } catch (error) {
      console.error('Error fetching vehicle data', error);
      setVehicleData([]); // Ensure vehicleData is always an array
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.transportfrom) newErrors.transportfrom = 'Transport from is required';
    if (!formData.transportto) newErrors.transportto = 'Transport to is required';
    if (!formData.transporttype) newErrors.transporttype = 'Transport type is required';
    if (!formData.vehicleyear) newErrors.vehicleyear = 'Vehicle year is required';
    if (!formData.vehiclemake) newErrors.vehiclemake = 'Vehicle make is required';
    if (!formData.vehiclemodel) newErrors.vehiclemodel = 'Vehicle model is required';
    if (!formData.operable) newErrors.operable = 'Operable status is required';
    if (!formData.vehiclecondition) newErrors.vehiclecondition = 'Vehicle condition is required';
    if (!formData.custemail) newErrors.custemail = 'Customer email is required';
    if (!formData.firstavaildate) newErrors.firstavaildate = 'First available date is required';
    if (!formData.custphone) newErrors.custphone = 'Customer phone is required';

    setErrors(newErrors);

    // Show validation error messages as toasts
    for (const key in newErrors) {
      toast.error(newErrors[key]);
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === 'vehiclemake') {
      const selectedMake = value;
      const models = vehicleData
        .filter(vehicle => vehicle.vehiclemake === selectedMake)
        .map(vehicle => vehicle.vehiclemodel);
      setModels(models);
      setFormData({
        ...formData,
        vehiclemake: value,
        vehiclemodel: '' // Reset vehicle model when make is changed
      });
    }
  };

  const validatePhoneNumber = (phone) => {
    // Regex for validating USA and Canada phone numbers in the format (000) 000-0000
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (!validatePhoneNumber(formData.custphone)) {
        setErrors({ ...errors, custphone: 'Invalid phone number. Please enter a valid USA or Canada phone number.' });
        toast.error('Invalid phone number. Please enter a valid USA or Canada phone number.');
        return;
      }

      submitShippingInfo(formData)
        .then(response => {
          const uniqueId = response.data.id; // Alphanumeric ID returned from backend
          toast.success('Data successfully inserted into the database!');
          setFormData({
            id: '',
            transportfrom: '',
            transportto: '',
            transporttype: 'Open', // Default value
            vehicleyear: '2010',
            vehiclemake: '',
            vehiclemodel: '',
            operable: 'Yes',
            vehiclecondition: 'Running',
            custemail: '',
            firstavaildate: 'As soon as possible',
            custphone: ''
          });
          navigate(`/Checkout/${uniqueId}`);
        })
        .catch(error => {
          console.error('There was an error submitting the form!', error);
          toast.error('There was an error submitting the form!');
        });
    }
  };

  return {
    yearOptions,
    dateOptions,
    formData,
    errors,
    handleChange,
    handleSubmit,
    vehicleData,
    models,
    setFormData, // Expose setFormData
  };
};

export const submitShippingInfo = (data) => {
  return axios.post('https://api.shoppervista.com/api/Shippinginfo', data);
};
