import React, { useState } from "react";
import { Elements, CardElement, useStripe, useElements,PaymentElement } from "@stripe/react-stripe-js"; 
import { loadStripe } from "@stripe/stripe-js";
import { Container } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import { useLocation } from 'react-router-dom';

// Load Stripe with your publishable key
const stripePromise = loadStripe("pk_test_51JHcjbB5YXKsaT5SvfVQpmajx8ewVrKn50wWubaB2uuclQHG9aSLo0RMVeJ2FCZZ3w8AAfHFUxGVAlnZzCapBDjc00u8ToTaza");

const StripetestForm = () => {
 
    const stripe = useStripe();
    const elements = useElements();

    const [billingAddress, setBillingAddress] = useState("");
    const [cardholdername, setcardholdername] = useState("");
     
    const [loading, setLoading] = useState(false);

    const location = useLocation();  
    const { selectedPrice } = location.state || {};  // Retrieve state from navigation


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            toast.error("Stripe is not loaded yet.");
            return;
        }

        setLoading(true);

        try {
            // Fetch client secret from backend
            const response = await fetch("http://localhost:4000/api/create-payment-intent", {
                method: "POST",
                headers: {"Content-Type": "application/json", },
                body: JSON.stringify({
                     amount: 111100, // Replace with your amount in cents 1,111.00
                    currency: "cad",
                }),
            });

            const { clientSecret } = await response.json();

            if (!clientSecret) {
                toast.error("Failed to retrieve client secret.");
                setLoading(false);
                return;
            }

            // Confirm the payment using Stripe.js
            const cardElement = elements.getElement(CardElement);

            if (!cardElement) {
                toast.error("Card details are missing.");
                setLoading(false);
                return;
            }

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: cardholdername, //"Test User", // Replace with user's name
                        address: {line1: billingAddress},
                    },
                },
            });
            setLoading(false);

            if (error) {
                toast.error(`Payment failed: ${error.message}`);
            } else if (paymentIntent.status === "succeeded") {
                toast.success("Payment successful!");
            }

        } catch (error) {
            console.error("Error during payment:", error);
            toast.error("An error occurred during the payment process.");
            setLoading(false);
        }
    };

    return (
        <Container className="mt-8">   
           <ToastContainer />
           <div className="row">            
                <div className="col-lg-6">   
           
                            <form onSubmit={handleSubmit}> 
                                 <h3>Enter Card Details</h3>
                                
                           { selectedPrice }  <CardElement  className="form-control" />
                                <div>
                                <br/>Name<br/>
                                    <input
                                        type="text" className="form-control" 
                                        placeholder="Enter card holder name"
                                        value={cardholdername}
                                        onChange={(e) => setcardholdername(e.target.value)}
                                    />
                                </div> <br/>
                                <div>
                                    Billing Address<br/>
                                    <input
                                        type="text" className="form-control" 
                                        placeholder="Enter billing address"
                                        value={billingAddress}
                                        onChange={(e) => setBillingAddress(e.target.value)}
                                    /> <br/><br/> 
                                </div>
                                <button type="submit" className="btn btn-primary" disabled={!stripe || loading}>
                                    {loading ? "Processing..." : "Pay"} 
                                </button>
                            </form>
                </div>
                    
            </div>
        </Container>
    );
};


const Stripetest = () => (
    <Elements stripe={stripePromise}>
        <StripetestForm /> 
    </Elements>   
);

export default Stripetest;
