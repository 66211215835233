import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useShippingInfoForm } from '../actions/Shippinginfo';
import { v4 as uuidv4 } from 'uuid'; // For generating unique alphanumeric IDs

const loadScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

export const Quote = () => {
  const { formData, errors, yearOptions, dateOptions, handleChange, handleSubmit, setFormData, models,vehicleData } = useShippingInfoForm();
  
  const autocompleteRefFrom = useRef(null);
  const autocompleteRefTo = useRef(null);

  useEffect(() => {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=AIzaSyBSQ2eHtJbnjd_PdMEPbxbm5Nl_3P2-9hw&libraries=places`);

    const handleScriptLoad = () => {
      if (window.google) {
        // Initialize Autocomplete for Transport From
        autocompleteRefFrom.current = new window.google.maps.places.Autocomplete(document.getElementById('transportfrom'), { types: ['geocode'] });
        autocompleteRefFrom.current.addListener('place_changed', () => {
          const place = autocompleteRefFrom.current.getPlace();
          setFormData(prevFormData => ({
            ...prevFormData,
            transportfrom: place.formatted_address
          }));
        });

        // Initialize Autocomplete for Transport To
        autocompleteRefTo.current = new window.google.maps.places.Autocomplete(document.getElementById('transportto'), { types: ['geocode'] });
        autocompleteRefTo.current.addListener('place_changed', () => {
          const place = autocompleteRefTo.current.getPlace();
          setFormData(prevFormData => ({
            ...prevFormData,
            transportto: place.formatted_address
          }));
        });
      }
    };

    const intervalId = setInterval(() => {
      if (window.google) {
        clearInterval(intervalId);
        handleScriptLoad();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [setFormData]);

        const handleFormSubmit = (event) => {
          event.preventDefault();

          // Generate alphanumeric ID and include it in the form data
          const alphanumericId = uuidv4();
          const updatedFormData = {
            ...formData,
            alphanumericId, // Add the generated alphanumeric ID
          };

          handleSubmit(updatedFormData);
        };
        
  return (      
    <Container className="mt-5 form-group">  <br/>        
      <ToastContainer  position="top-center"    />

       <h2>Get an Instant Quote</h2>  
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <Card style={{ backgroundColor: 'Navy', textAlign: 'center', color: 'white' }}>
              <Card.Header> </Card.Header>
              <Card.Body>
                <Card.Title>Instant Quote</Card.Title>
                <Card.Text>---------------</Card.Text>
              </Card.Body>
            </Card>
        
            <Col md={4}>
              <Form.Group controlId="formTransportFrom">
                <Form.Label className="labelclass">Transport From:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder='Transport From:'
                  name="transportfrom"
                  value={formData.transportfrom}
                  onChange={handleChange}
                  isInvalid={!!errors.transportfrom}
                  id="transportfrom"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.transportfrom}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="formTransportTo">
                <Form.Label className="labelclass">Transport To:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder='Transport To:'
                  name="transportto"
                  value={formData.transportto}
                  onChange={handleChange}
                  isInvalid={!!errors.transportto}
                  id="transportto"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.transportto}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>  

            <Col md={4}>
              <Form.Group as={Row} controlId="formTransportType" >
                <Form.Label as="legend" column sm={12} className="labelclass">Transport Type:</Form.Label>
                <Col sm={2}>
                  <Form.Check
                    type="radio"
                    label="Open"
                    name="transporttype"
                    value="Open"
                    checked={formData.transporttype === 'Open'}
                    onChange={handleChange}
                    isInvalid={!!errors.transporttype}
                    inline
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="radio"
                    label="Enclosed"
                    name="transporttype"
                    value="Enclosed"
                    checked={formData.transporttype === 'Enclosed'}
                    onChange={handleChange}
                    isInvalid={!!errors.transporttype}
                    inline
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.transporttype}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

          </Col>
        
        </Row>
        <Row>
        <hr className="custom-hr my-4" /> 
        <center> <h3>Vehicle Info </h3>   </center>

          <Col md={2}>
            <Form.Group controlId="formVehicleYear">
                <Form.Label className="labelclass">Vehicle Year:</Form.Label>
                <Form.Control
                as="select"
                name="vehicleyear"
                value={formData.vehicleyear}
                onChange={handleChange}
                isInvalid={!!errors.vehicleyear}
                >
                <option value="">Select Year</option>
                    {yearOptions}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                     {errors.vehicleyear}
                </Form.Control.Feedback>
            </Form.Group>
          </Col>
                
          <Col md={5}>
            <Form.Group controlId="formVehicleMake">
              <Form.Label className="labelclass">Vehicle Make:</Form.Label>
              <Form.Control
                as="select"
                name="vehiclemake"
                value={formData.vehiclemake}
                onChange={handleChange}
                isInvalid={!!errors.vehiclemake}
              >
                <option value="">Select Make</option>
                {Array.from(new Set(vehicleData.map(vehicle => vehicle.vehiclemake)))
                  .map((make, index) => (
                    <option key={index} value={make}>
                      {make}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.vehiclemake}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={5}>
            <Form.Group controlId="formVehicleModel">
              <Form.Label as="legend" column sm={12} className="labelclass"> Vehicle Model:</Form.Label>
              <Form.Control
                as="select"
                name="vehiclemodel"
                value={formData.vehiclemodel}
                onChange={handleChange}
                isInvalid={!!errors.vehiclemodel}
              >
                <option value="">Select Model</option>
                {models.map((model, index) => (
                  <option key={index} value={model}>
                    {model}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.vehiclemodel}
              </Form.Control.Feedback>
            </Form.Group>
          </Col> 
        </Row>
        <Row><hr className="custom-hr my-4" /> <br/>
          <Col md={6}>
              {/* Operable Section */}
              <Form.Group as={Row} controlId="formOperable"  >
                        <Form.Label column sm={12} className="custom-label">
                          Operable:
                        </Form.Label>
                        <Col sm={12} className="d-flex align-items-center">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="operable"
                            value="Yes"
                            checked={formData.operable === 'Yes'}
                            onChange={handleChange}
                            isInvalid={!!errors.operable}
                            inline
                          /> <hr className="custom-hr my-1" />                         
                          <Form.Check
                            type="radio"
                            label="No"
                            name="operable"
                            value="No"
                            checked={formData.operable === 'No'}
                            onChange={handleChange}
                            isInvalid={!!errors.operable}
                            inline
                          />
                        </Col>
                        <Form.Control.Feedback type="invalid">
                          {errors.operable}
                        </Form.Control.Feedback>
                      </Form.Group>
          </Col>
          <Col md={6}>
              {/* Vehicle Condition Section */}
              <Form.Group as={Row} controlId="formVehicleCondition">
                        <Form.Label column sm={12} className="custom-label">
                          Vehicle Condition:
                        </Form.Label>
                        <Col sm={12} className="d-flex align-items-center">
                          <Form.Check
                            type="radio"
                            label="Running"
                            name="vehiclecondition"
                            value="Running"
                            checked={formData.vehiclecondition === 'Running'}
                            onChange={handleChange}
                            isInvalid={!!errors.vehiclecondition}
                            inline
                          />
                       <hr className="custom-hr my-1" />  
                          <Form.Check
                            type="radio"
                            label="Non-running"
                            name="vehiclecondition"
                            value="Non-running"
                            checked={formData.vehiclecondition === 'Non-running'}
                            onChange={handleChange}
                            isInvalid={!!errors.vehiclecondition}
                            inline
                          />
                        </Col>
                        <Form.Control.Feedback type="invalid">
                          {errors.vehiclecondition}
                        </Form.Control.Feedback>
                      </Form.Group>
          </Col>
          <br/><br/>
        </Row>

        {/* Customer Info */}
        <Row>
          <hr className="custom-hr my-4" /> 
        <center> <h3>Customer Info </h3>   </center>

          <Col md={4}> 
            <Form.Group controlId="formCustEmail">
            <Form.Label as="legend" column sm={12} className="labelclass">Email:</Form.Label>
              <Form.Control
                type="email" placeholder='youremail@email.com'
                name="custemail"
                value={formData.custemail}
                onChange={handleChange}
                isInvalid={!!errors.custemail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.custemail}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

        <Col md={4}>
                <Form.Group controlId="formFirstAvailDate">
                <Form.Label className="labelclass">First Available Date:</Form.Label>
                  <Form.Control
                    as="select"
                    name="firstavaildate"
                    value={formData.firstavaildate}
                    onChange={handleChange}
                    isInvalid={!!errors.firstavaildate}
                  >
                    <option value="">Select Date</option>
                    {dateOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.firstavaildate}
                  </Form.Control.Feedback>
                </Form.Group>
        </Col>
       
          <Col md={4}>
            <Form.Group controlId="formCustPhone">
              <Form.Label  className="labelclass" >Phone:</Form.Label>
              <Form.Control
                type="tel"
                name="custphone"
                value={formData.custphone}
                onChange={handleChange}
                isInvalid={!!errors.custphone}
                placeholder="123-456-7899"
              />
              <Form.Control.Feedback type="invalid">
                {errors.custphone}
              </Form.Control.Feedback>
            </Form.Group>
          </Col> 
        </Row><br/> 
        <Button type="submit" className="btn-custom btn-lg mt-3">Submit</Button>
      </Form>     
    </Container>
  );
};
