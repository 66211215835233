import { toast } from "react-toastify";
//import { usePaymentLogic  } from '../actions/Payments';        // Import the navigation logic


// Navigation logic to handle section transitions
export const navigateTo = (section) => {
    // Get all sections and headers
    const sections = document.querySelectorAll('.section');
    const headers = document.querySelectorAll('.header div');
  
    // Hide all sections
    sections.forEach((sec) => sec.classList.remove('active'));
  
    // Reset header active state
    headers.forEach((header) => header.classList.remove('active'));
  
    // Show the target section
    const targetSection = document.getElementById(`${section}-section`);
    if (targetSection) {
      targetSection.classList.add('active');
    }
  
    // Highlight the corresponding header
    const targetHeader = document.getElementById(`${section}-header`);
    if (targetHeader) {
      targetHeader.classList.add('active');
    }
  };

 

export const validateShippingInfo = (shippingData, setActiveSection,cardInfo) => {
   // const {activeSection, setActiveSection  } = usePaymentLogic();    
    const focusField = (fieldName) => {
        const field = document.getElementsByName(fieldName)[0];
        if (field) field.focus();
    };

    if (!shippingData.youAre) {
        toast.error("Please select if you are an Individual or a Business.");
        setActiveSection("transport");
        focusField("youAre");
        return false;
    }
    if (!shippingData.fullName.trim()) {
        toast.error("Full Name is required.");
        setActiveSection("transport");
        focusField("fullName");
        return false;
    }
    if (!shippingData.custEmail.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(shippingData.custEmail)) {
        toast.error("A valid Email is required.");
        setActiveSection("transport");
        focusField("custEmail");
        return false;
    }
    if (!shippingData.custPhone.trim() || !/^\d{10}$/.test(shippingData.custPhone)) {
        toast.error("A valid 10-digit Phone Number is required.");
        setActiveSection("transport");
        focusField("custPhone");
        return false;
    }
    if (!shippingData.pickupAddress.trim()) {
        toast.error("Pickup Address is required.");
        setActiveSection("pickup");
        focusField("pickupAddress");
        return false;
    }
    if (!shippingData.thisIsA) {
        toast.error("Please select the Pickup Type (Residential or Business).");
        setActiveSection("pickup");
        focusField("thisIsA");
        return false;
    }
    if (!shippingData.pickupContact) {
        toast.error("Please specify who to contact for Pickup.");
        setActiveSection("pickup");
        focusField("pickupContact");
        return false;
    }
    if (shippingData.pickupContact === "SomeoneElse") {
        if (!shippingData.contactFullName.trim()) {
            toast.error("Pickup Contact's Full Name is required.");
            setActiveSection("pickup");
            focusField("contactFullName");
            return false;
        }
        if (!shippingData.pickupPhone.trim() || !/^\d{10}$/.test(shippingData.pickupPhone)) {
            toast.error("A valid 10-digit Pickup Contact Phone Number is required.");
            setActiveSection("pickup");
            focusField("pickupPhone");
            return false;
        }
    }
    if (!shippingData.deliveryAddress.trim()) {
        toast.error("Delivery Address is required.");
        setActiveSection("delivery");
        focusField("deliveryAddress");
        return false;
    }
    if (!shippingData.thisIsADelivery) {
        toast.error("Please select the Delivery Type (Residential or Business).");
        setActiveSection("delivery");
        focusField("thisIsADelivery");
        return false;
    }
    if (!shippingData.deliveryContact) {
        toast.error("Please specify who to contact for Delivery.");
        setActiveSection("delivery");
        focusField("deliveryContact");
        return false;
    }
    if (shippingData.deliveryContact === "SomeoneElse") {
        if (!shippingData.deliveryFullName.trim()) {
            toast.error("Delivery Contact's Full Name is required.");
            setActiveSection("delivery");
            focusField("deliveryFullName");
            return false;
        }
        if (!shippingData.deliveryPhone.trim() || !/^\d{10}$/.test(shippingData.deliveryPhone)) {
            toast.error("A valid 10-digit Delivery Contact Phone Number is required.");
            setActiveSection("delivery");
            focusField("deliveryPhone");
            return false;
        }
    }
   
    // if (!/^\d{16}$/.test(cardInfo.entercardnumber)) {
    //     toast.error("Card number must be 16 digits. and Number.");
    //     document.getElementsByName("entercardnumber")[0].focus();
    //     return false;
    //   }
    //   if (!cardInfo.cardnameoncard.trim()) {
    //     toast.error("Name on card is required.");
    //     document.getElementsByName("cardnameoncard")[0].focus();
    //     return false;
    //   }
    //   if (!/^\d{4}$/.test(cardInfo.expidate)) {
    //     toast.error("Expiration date must be 4 digits (MMYY). and Number.");
    //     document.getElementsByName("expidate")[0].focus();
    //     return false;
    //   }
    //   if (!/^\d{3,4}$/.test(cardInfo.securitycodecvv)) {
    //     toast.error("CVV must be 3 or For AMX 4 digits.and Number.");
    //     document.getElementsByName("securitycodecvv")[0].focus();
    //     return false;
    //   }
    //   if (!cardInfo.billingaddress) {
    //     toast.error("Billing address is required.");
    //     return false;
    //   } 

    return true;
};
